import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import SEO from "../components/seo"

const trainingPlayers = () => {
  return (
    <Layout>
      <SEO
        title="Training Players"
        description="Learn how to train your players effectively, so that their stats and Overall increases as fast as possible."
      />
      <div className="container">
        <h1>Training Players</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Training Players in PES 2021</li>
        </ul>
        <p>
          Player Levels are important to understand a player's potential, and
          they received a revamp when PES 2020 was released.
        </p>
        <p>
          In this section, let's learn all about levels and how to train players
          correctly in PES 2021 - Season Update.
        </p>
      </div>
      <nav id="toc">
        <div className="container">
          <span>Table of Contents</span>
          <ul>
            <li>
              <AnchorLink href="#playerlevels" offset="70">
                Player Levels
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#convertingplayers" offset="70">
                Converting Players into EXP Trainers
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#maximisingExp" offset="70">
                Maximising EXP
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#items" offset="70">
                EXP, Position and Skill Trainer Items
              </AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container">
        <h2 id="playerlevels">Player Levels</h2>
        <p>
          All players you get will be level 1 - this is their "default state"
          since PES 2020 was released. In the past, level 30 represented the
          baseline level of performance for all players, so if you are familiar
          with previous iterations of myClub, this basically means that level 1
          is the new level 30.
        </p>
        <p>
          Their Overall Rating at level 1 will determine the player's Ball Type
          (White, Bronze, Silver, Gold or Black). Depending on the player's age
          and potential for growth, they will have a different max level
          ceiling.
        </p>
        <p>
          This means that gold ball players (and even some silvers) can be rated
          higher than 85 when maxed out - which is good, because they are
          cheaper to maintain.{" "}
          <Link to="/best-players-ball-type/">
            You can have a look at some of the best Gold, Silver and Bronze
            players here
          </Link>
          .
        </p>
        <p>
          To reach your players' max level, you will need to train them to
          upgrade their stats. To do so, you will need to play a lot (each game
          will give your players a bit of EXP, depending on minutes played, star
          rating and difficulty setting) and/or convert players you no longer
          want into EXP Trainers.
        </p>
        <p>
          You can use this handy{" "}
          <a
            href="https://docs.google.com/spreadsheets/d/1lSj0B0grwqohhia4XaB97xfknZ6zKEzuh1r54427FEs/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contract and Experience Calculator Spreadsheet for PES 2021
          </a>{" "}
          (check the "Experience Calculator Tab") to see how much EXP you need
          to gather to max out a player.
        </p>
        <h2 id="convertingplayers">Converting Players into EXP Trainers</h2>
        <p>
          Throughout the season, you will get many players that you don’t like,
          don’t fit your play style or simply don’t need.
        </p>
        <p>
          When you have players you won’t use in your games, you might as well
          convert them into EXP trainers to “feed” them to your favourite
          players.
        </p>
        <p>
          I usually wait until their contracts run out, and instead of renewing,
          I convert them into trainers. If they are White Balls they won’t have
          a lot of EXP to give, but it all adds up. You can store up to 100
          trainers.
        </p>
        <p>
          Each player has different potential. Just like in real life, young
          players will have a higher ceiling - a player like Vinícius Júnior
          (gold ball, 82 rated) can be trained all the way up to become a Black
          Ball left winger… a lot of effort to train, but a much cheaper
          contract to renew!
        </p>
        <p>
          To make sure you don't accidentally convert your favorite players into
          trainers, you can lock your players inside the CLUB HOUSE / myClub
          MEMBERS screen (just pick your player(s) and press the "Player Menu"
          button).
        </p>
        <h2 id="maximisingExp">Maximising EXP</h2>
        <p>
          When you’re training your players, make sure you take both their
          position and their trainer’s position into consideration. In other
          words, you should train a CF with a CF, or a DMF with another DMF to
          maximise compatibility, thus more EXP.
        </p>
        <p>
          In PES 2019, the level of the player you were converting into trainer
          was something important to consider. Their base EXP increases every 10
          levels - so if you had a player at level 18, you might as well reach
          level 20 before converting him into a trainer. For PES 2019, you could
          use this very handy{" "}
          <a
            href="https://www.filedropper.com/baseexperiencecalculator"
            target="_blank"
            rel="noopener noreferrer"
          >
            Base Experience Calculator
          </a>{" "}
          to determine how much Base Experience you can get.
        </p>
        <p>
          You can see the minimum guaranteed points you will get before
          submitting for training, but this is just the “worst case scenario” -
          taking just the position into account.
        </p>
        <p>
          You can actually get even more EXP if you’re lucky and you get a
          Multiplier. But how does that work?
        </p>
        <h3>Multipliers</h3>
        <p>
          Multipliers, as the name says, multiply the base EXP you can get from
          a trainer.
        </p>
        <p>
          There are apparently 6 possible multipliers you can randomly get: 1x,
          2.4x, 5.75x, 13.8x, 33x and 79.2x. You’ll be real lucky if you ever
          see the last one!
        </p>
        <p>
          You can increase your odds of getting a high multiplier if you look at
          the traits of your players and the trainers you have at your disposal.
        </p>
        <p>
          When you're training a player, if you look closely at the "Training
          Details" screen, you will see 3 fields:
        </p>
        <ul>
          <li>Nationality/Region</li>
          <li>Current Club</li>
          <li>
            <Link to="/playing-styles/">Playing Style</Link>
          </li>
        </ul>
        <p>
          These 3 factors are the ones you should take into consideration if you
          want to increase your chances of getting a higher multiplier.
        </p>
        <p>
          Let's use Jemerson as an example: he is a Brazilian CB that plays in
          Monaco and has a “Build Up”{" "}
          <Link to="/playing-styles/">Playing Style</Link>. If you train him
          with another Brazilian CB that has that exact Playing Style, you’re
          more likely to get a high multiplier.
        </p>
        <h3>Training with Duplicate Players</h3>
        <p>
          This is probably the best way to max out a player you really like.
        </p>
        <p>
          Duplicates get 3x the base experience when training each other, so
          you're definitely giving your player a bigger boost - and what's even
          better is that since they both have everything in common, you are
          increasing the odds of receiving a high multiplier, as seen in the
          previous section.
        </p>
        <p>
          How do you get Duplicate Players? You can either spin a lot of Agents
          (and I mean a lot!) or you can continuously scout, train, and convert
          those players into trainers - this is the approach most people use,
          although it's pretty time-consuming.
        </p>
        <p>
          Keep in mind you can't scout a player you already have in your squad -
          so you need to keep converting them into trainers before scouting a
          new one.
        </p>
        <p>
          Let's say you want to train Naldo: you can scout him, train him up to
          level 10 or 20 to get bigger base EXP, convert him as trainer, and
          repeat this process all over again. When you feel like you have enough
          Naldos in your training inventory, you can then scout Naldo one final
          time and train him with all your trainers.
        </p>
        <p>
          One thing to note is that featured players are actually different from
          the regular players, so you can scout the regular versions to max out
          your featured players. This is actually my favorite way to level up
          players.
        </p>
        <p>
          If you have millions of GP (or coins) and would rather spin agents,
          you can reset the box as soon as you get the player you want to give
          it another go.
        </p>
        <h2 id="items">EXP, Position and Skill Trainer Items</h2>
        <p>
          Every now and then, new competitions become available where you can
          participate and win certain items to apply to your players. You can
          get:
        </p>
        <ul>
          <li>
            EXP Trainers, an item with a certain amount of points (like 4.000
            EXP) that you can use on one of your favourite players;
          </li>
          <li>
            Position Trainers, an item that will randomly max out one of your
            player’s less popular positions;
          </li>
          <li>
            Skill Trainer, an item that will give your player a random new skill
            (such as Low Punt for Goalkeepers). Have a look at the full list of{" "}
            <Link to="/player-skills/">available Player Skills</Link>.
          </li>
        </ul>
        <p>
          You can use some <Link to="/coins-gp/">eFootball points</Link> (new in
          PES 2021) to purchase some Position or Skill Trainers for 500 points
          each.
        </p>
      </div>
    </Layout>
  )
}

export default trainingPlayers
